import { TableCell } from '@mui/material'
import type { InvoicingItem } from '@orus.eu/backend/src/invoicing/views/invoice-payment-status-view'
import { InvoicingItemStatusTag } from '../../organisms/invoicing/invoicing-item-status-tag'

type TableCellInvoicingItemStatusTagProps = {
  invoicingItem: InvoicingItem
}

export function TableCellInvoicingItemStatusTag({ invoicingItem }: TableCellInvoicingItemStatusTagProps): JSX.Element {
  return (
    <TableCell>
      <InvoicingItemStatusTag invoicingItem={invoicingItem} />
    </TableCell>
  )
}

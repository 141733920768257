import { Typography } from '@mui/material'
import type { InvoiceCancellationProblem } from '@orus.eu/backend/src/invoicing/services/invoice-lifecycle-service'
import { Button, LegacyDialog, spacing, useAsyncCallback } from '@orus.eu/pharaoh'
import { isSuccess } from '@orus.eu/result'
import { useState } from 'react'
import { trpc } from '../../../client'
import { validateRequiredField } from '../../../lib/validation'

import { ValidatedTextField } from '../../molecules/validated-text-field'
import { WithLabel } from '../with-label'

type CancelInvoiceButtonProps = {
  invoiceId: string
  refetch: () => Promise<unknown>
}

/**
 * This button attempts the cancellation of an invoice when clicked. After it displays the result and cause the
 * refetch of invoices list, to make sure all information on the page is up-to-date.
 * @param invoiceId
 * @constructor
 */
export function CancelInvoiceButton({ invoiceId, refetch }: CancelInvoiceButtonProps): JSX.Element {
  const [state, setState] = useState<'idle' | 'confirming' | 'working'>('idle')

  const attemptCancellation = useAsyncCallback(
    async (reason: string) => {
      setState('working')

      const result = await trpc.invoices.cancelEmittedInvoice.mutate({ invoiceId, reason })

      alert(isSuccess(result) ? 'Facture annulée' : getProblemExplanation(result.problem))

      await refetch()
    },
    [invoiceId, refetch],
  )

  return (
    <>
      <Button
        size="small"
        variant="secondary"
        icon="ban-regular"
        disabled={state !== 'idle'}
        onClick={() => setState('confirming')}
        isLoading={state === 'working'}
        title="Annuler la facture"
      />
      {state === 'confirming' ? (
        <CancelInvoiceDialog invoiceId={invoiceId} onClose={() => setState('idle')} onValidate={attemptCancellation} />
      ) : (
        <></>
      )}
    </>
  )
}

type CancelInvoiceDialogProps = {
  invoiceId: string
  onClose: () => void
  onValidate: (reason: string) => void
}

function CancelInvoiceDialog(props: CancelInvoiceDialogProps): JSX.Element {
  const [reason, setReason] = useState<string | null>(null)

  const submitEnabled = !!reason

  const handleSubmit = () => {
    if (!submitEnabled) {
      return
    }

    props.onValidate(reason)
  }

  return (
    <LegacyDialog
      variant="backoffice"
      style="danger"
      title="Annuler la facture ?"
      onClose={props.onClose}
      onSubmit={handleSubmit}
      submitLabel="Confirmer"
      submitDisabled={!submitEnabled}
      secondaryActionLabel="Ne rien faire"
      onSecondaryAction={props.onClose}
    >
      <Typography>Tu t&apos;apprêtes à annuler cette facture, tu ne pourras plus modifier son statut.</Typography>
      <WithLabel label="Raison" sx={{ marginTop: spacing[70] }}>
        <ValidatedTextField
          initialValue=""
          placeholder="Pour quelle raison"
          validator={validateRequiredField}
          onChange={setReason}
        />
      </WithLabel>
    </LegacyDialog>
  )
}

function getProblemExplanation(problem: InvoiceCancellationProblem): string {
  switch (problem) {
    case 'non-v1':
      return 'Seules les factures V1 peuvent être annulées. Pour les factures V2, une facture d’ajustement est générée automatiquement si nécessaire.'
    case 'non-valid':
      return "La facture n'est pas au status validée (peut être qu'elle a été annulée dans un autre onglet)"
    case 'already-paid':
      return "La facture a été payée entretemps, on ne peut plus l'annuler"
  }
}

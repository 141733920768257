import type { PaymentStatus } from '@orus.eu/backend/src/events/payment-update'

export const statusAllowsMed: Record<PaymentStatus, boolean> = {
  pending: false,
  paid: false,
  new: false,
  failed: true,
  disputed: true,
  refunded: true,
  cancelled: true,
}

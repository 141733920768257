import type { InvoiceDeletionProblem } from '@orus.eu/backend/src/invoicing/services/invoice-lifecycle-service'
import { Button, LegacyDialog, Text, useAsyncCallback } from '@orus.eu/pharaoh'
import { isSuccess } from '@orus.eu/result'
import { useCallback, useState } from 'react'
import { trpc } from '../../../client'

type DeleteInvoiceButtonProps = {
  invoiceId: string
  refetch: () => Promise<unknown>
}

/**
 * This button attempts the deletion of an invoice draft when clicked. After it displays the result and cause the
 * refetch of invoices list, to make sure all information on the page is up-to-date.
 * @param invoiceId
 * @constructor
 */
export function DeleteInvoiceButton({ invoiceId, refetch }: DeleteInvoiceButtonProps): JSX.Element {
  const [state, setState] = useState<'idle' | 'confirming' | 'working'>('idle')

  const attemptDeletion = useAsyncCallback(async () => {
    setState('working')

    const result = await trpc.invoices.deleteDraftInvoice.mutate(invoiceId)

    alert(isSuccess(result) ? 'Brouillon supprimé' : getProblemExplanation(result.problem))
    await refetch()
  }, [invoiceId, refetch])

  return (
    <>
      <Button
        size="small"
        variant="tertiary"
        icon="trash-can-regular"
        disabled={state !== 'idle'}
        onClick={() => setState('confirming')}
        isLoading={state === 'working'}
      />
      {state === 'confirming' ? (
        <DeleteInvoiceDialog onClose={() => setState('idle')} onValidate={attemptDeletion} />
      ) : (
        <></>
      )}
    </>
  )
}

type DeleteInvoiceDialogProps = {
  onClose: () => void
  onValidate: () => void
}

function getProblemExplanation(problem: InvoiceDeletionProblem): string {
  switch (problem) {
    case 'non-draft':
      return "La facture n'est pas un brouillon (peut être qu'elle a été validée ou supprimée dans un autre onglet)"
  }
}

function DeleteInvoiceDialog(props: DeleteInvoiceDialogProps): JSX.Element {
  const { onClose, onValidate } = props

  const handleSubmit = useCallback(() => {
    onValidate()
  }, [onValidate])

  return (
    <LegacyDialog
      variant="backoffice"
      style="danger"
      title="Supprimer le brouillon ?"
      onClose={onClose}
      onSubmit={handleSubmit}
      submitLabel="Confirmer"
      secondaryActionLabel="Ne rien faire"
      onSecondaryAction={props.onClose}
    >
      <Text>Tu t&apos;apprêtes à supprimer le brouillon sélectionné, il va disparaître à tout jamais.</Text>
    </LegacyDialog>
  )
}

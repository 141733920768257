import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  type SxProps,
} from '@mui/material'
import type { Amount } from '@orus.eu/amount'
import type { PaymentStatus } from '@orus.eu/backend/src/events/payment-update'
import type { PaymentProviderName } from '@orus.eu/backend/src/events/stripe-object-update'
import type {
  InvoiceMedStatus,
  InvoicingItemWithMedStatus,
} from '@orus.eu/backend/src/invoicing/views/invoice-payment-status-view'
import {
  Button,
  ButtonLink,
  colors,
  enqueueTemporaryNotificationAlert,
  useAsyncCallback,
  type ButtonVariant,
} from '@orus.eu/pharaoh'
import { ButtonGroup } from '@orus.eu/pharaoh/src/components/button/button-group'
import { isFailure } from '@orus.eu/result'
import { useState } from 'react'
import { trpc } from '../../../client'
import { formatDateTime } from '../../../lib/format'
import { getInvoicePeriodLabel, getInvoicePeriodLabelFromTimestamps } from '../../../lib/invoice-util'
import { getGocardlessPaymentIntentUrl, getStripePaymentIntentUrl } from '../../../lib/payment-provider-util'
import { useSession } from '../../../lib/session'
import { oneMinute, useCurrentTimestamp } from '../../../lib/use-current-timestamp'
import { usePermissions } from '../../../lib/use-permissions'
import { TableCellAmount } from '../../molecules/table-elements/table-cell-amount'
import { TableCellHeader } from '../../molecules/table-elements/table-cell-header'
import { TableCellInvoicingItemStatusTag } from '../../molecules/table-elements/table-cell-invoicing-item-status-tag'
import { TableCellText } from '../../molecules/table-elements/table-cell-text'
import { CancelInvoiceButton } from './cancel-invoice-button'
import { DeleteInvoiceButton } from './delete-invoice-button'
import { InvoiceDocumentsDownloadButtons } from './invoice-documents-download-buttons'
import { DraftInvoiceStatusTag } from './invoicing-item-status-tag'
import { ManualPaymentButton } from './ManualPaymentButton'
import { PayInvoiceButton } from './pay-invoice-button'
import { statusAllowsMed } from './types'
import { ValidateInvoiceButton } from './validate-invoice-button'
type InvoiceListProps = {
  invoicingItems: InvoicingItemWithMedStatus[]
  refetch: () => Promise<unknown>
  /**
   * Allow displaying a draft preview row. Leave undefined to have no preview row.
   * Set to null to have a blank preview row.
   * Set to a non-null value to have a filled draft preview row.
   */
  draftPreviewRow?: DraftPreviewRowProps | null
  sx?: SxProps
}

export function InvoiceList(props: InvoiceListProps): JSX.Element {
  const { invoicingItems, draftPreviewRow, sx, refetch } = props
  const orderedInvoicingItems = [...invoicingItems]
  orderedInvoicingItems.sort((a, b) => {
    return b.invoice.dueTimestamp - a.invoice.dueTimestamp
  })
  const session = useSession()
  const { permissions } = usePermissions()

  return (
    <TableContainer sx={sx}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCellHeader>Due le</TableCellHeader>
            <TableCellHeader>Période</TableCellHeader>
            <TableCellHeader>Statut</TableCellHeader>
            <TableCellHeader>Montant</TableCellHeader>
            <TableCellHeader>Document</TableCellHeader>
            {session.permissions.type === 'platform' && permissions.includes('invoices.create') ? (
              <TableCellHeader>Action</TableCellHeader>
            ) : null}
          </TableRow>
        </TableHead>
        <TableBody>
          {draftPreviewRow === null ? <BlankPreviewRow /> : <></>}
          {draftPreviewRow ? <DraftPreviewRow {...draftPreviewRow} /> : <></>}
          {orderedInvoicingItems.map((invoicingItem) => (
            <InvoiceListRow key={invoicingItem.invoice.invoiceId} invoicingItem={invoicingItem} refetch={refetch} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

type InvoiceListRowProps = {
  invoicingItem: InvoicingItemWithMedStatus
  refetch: () => Promise<unknown>
}

const medStatusToButtonTitle: { [key in InvoiceMedStatus]: string } = {
  sent: 'MED envoyée',
  'in-progress': "MED en cours d'envoi",
  'not-sent': 'Envoyer MED',
}

function InvoiceListRow({ invoicingItem, refetch }: InvoiceListRowProps): JSX.Element {
  const { invoice, type } = invoicingItem
  const invoiceId = invoice.invoiceId
  const [synchronizing, setSynchronizing] = useState(false)
  const [sendingMed, setSendingMed] = useState(false)
  const session = useSession()
  const { permissions } = usePermissions()

  const synchronizeInvoicePaymentStatus = useAsyncCallback(async () => {
    setSynchronizing(true)
    await trpc.invoices.synchronizeInvoicePaymentAttempts.mutate(invoiceId)
    await refetch()
  }, [invoiceId, refetch])

  const sendMed = useAsyncCallback(async () => {
    setSendingMed(true)
    try {
      const result = await trpc.invoices.sendInvoiceMedLetter.mutate(invoiceId)
      if (isFailure(result)) {
        let errorMessage

        switch (result.problem.type) {
          case 'missing-address':
            errorMessage = "L'adresse du client est manquante"
            break
          case 'address-too-long':
            errorMessage = "L'adresse du client est trop longue"
            break
          case 'med-letter-already-sent':
            errorMessage = "La lettre de MED est déjà en cours d'envoi"
            break
          case 'temporary-api-failure':
            errorMessage = "Une erreur temporaire est survenue lors de l'envoi de la lettre de MED, veuillez réessayer."
            break
          case 'network-error':
            errorMessage = "Une erreur de réseau est survenue lors de l'envoi de la lettre de MED, veuillez réessayer."
            break
          default:
            errorMessage = "Une erreur est survenue lors de l'envoi de la lettre de MED"
            break
        }

        enqueueTemporaryNotificationAlert(errorMessage, {
          variant: 'danger',
          autoHideDuration: 3000,
        })
        return
      }
      enqueueTemporaryNotificationAlert('La lettre de MED a été envoyée avec succès', {
        variant: 'success',
        autoHideDuration: 3000,
      })
      await refetch()
    } finally {
      setSendingMed(false)
    }
  }, [invoiceId, refetch])

  let lastTentativePaymentIntentId: string | undefined
  let lastTentativePaymentProvider: PaymentProviderName | undefined
  if (type === 'emitted') {
    lastTentativePaymentIntentId = invoicingItem.lastPaymentTentativeIntentId
    lastTentativePaymentProvider = invoicingItem.lastPaymentTentativeProvider
  }

  return (
    <TableRow key={invoiceId} title={'items' in invoice ? 'Facture V1' : 'Facture V2'}>
      <TableCellText text={formatDateTime(invoice.dueTimestamp)} />
      <TableCellText text={getInvoicePeriodLabel(invoice)} />
      <TableCellInvoicingItemStatusTag invoicingItem={invoicingItem} />
      <TableCellAmount amount={invoice.totalPremium} />
      <TableCell>
        <InvoiceDocumentsDownloadButtons invoicingItem={invoicingItem} />
      </TableCell>
      {session.permissions.type === 'platform' && permissions.includes('invoices.create') ? (
        <TableCell>
          <ButtonGroup>
            {invoicingItem.type === 'emitted' && statusAllowsMed[invoicingItem.paymentStatus] ? (
              <Button
                disabled={sendingMed || ['sent', 'in-progress'].includes(invoicingItem.medStatus)}
                variant="secondary"
                size="small"
                icon={'envelope-open-text-regular'}
                title={medStatusToButtonTitle[invoicingItem.medStatus]}
                aria-label={medStatusToButtonTitle[invoicingItem.medStatus]}
                onClick={sendMed}
              />
            ) : null}
            <InvoiceActionValidate invoicingItem={invoicingItem} refetch={refetch} />
            <InvoiceActionDelete invoicingItem={invoicingItem} refetch={refetch} />
            <InvoiceActionMarkAsPaid invoicingItem={invoicingItem} refetch={refetch} />
            <InvoiceActionCancel invoicingItem={invoicingItem} refetch={refetch} />
            {permissions.includes('stripe.read') ? (
              <Button
                disabled={synchronizing}
                variant="secondary"
                size="small"
                icon="arrows-rotate-regular"
                title="Synchroniser le statut de paiement"
                onClick={synchronizeInvoicePaymentStatus}
              ></Button>
            ) : null}
            <InvoiceActionPay invoicingItem={invoicingItem} refetch={refetch} />
            {lastTentativePaymentIntentId && permissions.includes('stripe.read') ? (
              <PaymentProviderLink
                variant="secondary"
                provider={lastTentativePaymentProvider}
                paymentIntentId={lastTentativePaymentIntentId}
              />
            ) : null}
          </ButtonGroup>
        </TableCell>
      ) : null}
    </TableRow>
  )
}

export type DraftPreviewRowProps = {
  dueTimestamp: number
  periodStartTimestamp: number
  periodEndTimestamp: number
  totalPremium: Amount
}

function DraftPreviewRow(props: DraftPreviewRowProps): JSX.Element {
  return (
    <TableRow
      sx={{
        backgroundColor: colors.yellow[200],
        outline: `2px solid ${colors.red[500]}`,
      }}
    >
      <TableCellText text={formatDateTime(props.dueTimestamp)} />
      <TableCellText text={getInvoicePeriodLabelFromTimestamps(props)} />
      <TableCell>
        <DraftInvoiceStatusTag />
      </TableCell>
      <TableCellAmount amount={props.totalPremium} />
      <TableCell />
      <TableCell />
    </TableRow>
  )
}

function BlankPreviewRow(): JSX.Element {
  return (
    <TableRow>
      <TableCell colSpan={6}>
        <Typography
          component="p"
          sx={{
            color: colors.gray[200],
            textAlign: 'center',
            padding: '2px', // have same height as rows with tags
          }}
        >
          Un aperçu sera généré une fois tous les champs remplis !
        </Typography>
      </TableCell>
    </TableRow>
  )
}

type InvoiceActionProps = {
  invoicingItem: InvoicingItemWithMedStatus
  refetch: () => Promise<unknown>
}

function InvoiceActionPay({ invoicingItem, refetch }: InvoiceActionProps): JSX.Element {
  const currentTimestamp = useCurrentTimestamp(oneMinute)

  if (
    invoicingItem.type === 'emitted' &&
    invoicingItem.invoice.status === 'valid' &&
    payableInvoiceStatus[invoicingItem.paymentStatus] &&
    invoicingItem.invoice.dueTimestamp <= currentTimestamp
  ) {
    return (
      <PayInvoiceButton
        invoiceId={invoicingItem.invoice.invoiceId}
        confirmText="Tentative de paiement lancée"
        refetch={refetch}
      >
        Payer
      </PayInvoiceButton>
    )
  }

  return <></>
}

function InvoiceActionMarkAsPaid({ invoicingItem, refetch }: InvoiceActionProps): JSX.Element {
  const currentTimestamp = useCurrentTimestamp(oneMinute)

  if (
    invoicingItem.type === 'emitted' &&
    invoicingItem.invoice.status === 'valid' &&
    invoicingItem.invoice.dueTimestamp <= currentTimestamp
  ) {
    return (
      <ManualPaymentButton
        invoiceId={invoicingItem.invoice.invoiceId}
        targetStatus={invoicingItem.paymentStatus === 'paid' ? 'failed' : 'paid'}
        refetch={refetch}
      />
    )
  }

  return <></>
}

function InvoiceActionValidate({ invoicingItem, refetch }: InvoiceActionProps): JSX.Element {
  if (invoicingItem.type === 'draft') {
    return (
      <ValidateInvoiceButton invoiceId={invoicingItem.invoice.invoiceId} refetch={refetch}>
        Valider
      </ValidateInvoiceButton>
    )
  }

  return <></>
}

function InvoiceActionDelete({ invoicingItem, refetch }: InvoiceActionProps): JSX.Element {
  if (invoicingItem.type === 'draft') {
    return <DeleteInvoiceButton invoiceId={invoicingItem.invoice.invoiceId} refetch={refetch} />
  }

  return <></>
}

function InvoiceActionCancel({ invoicingItem, refetch }: InvoiceActionProps): JSX.Element {
  if (
    invoicingItem.type === 'emitted' &&
    ((invoicingItem.invoice.status === 'valid' && invoicingItem.paymentStatus === 'new') ||
      invoicingItem.paymentStatus === 'failed' ||
      invoicingItem.paymentStatus === 'cancelled' ||
      invoicingItem.paymentStatus === 'refunded')
  ) {
    return <CancelInvoiceButton invoiceId={invoicingItem.invoice.invoiceId} refetch={refetch} />
  }

  return <></>
}

export function PaymentProviderLink(props: {
  paymentIntentId: string
  provider: PaymentProviderName | undefined
  variant?: ButtonVariant
  showCancelAction?: boolean
}): JSX.Element {
  const { paymentIntentId, provider, variant } = props

  return (
    <ButtonLink
      size="small"
      variant={variant}
      icon="arrow-up-right-from-square-regular"
      avatarPosition="right"
      to={
        provider === 'gocardless'
          ? getGocardlessPaymentIntentUrl(paymentIntentId)
          : getStripePaymentIntentUrl(paymentIntentId)
      }
      target="_blank"
    >
      {provider === 'gocardless' ? 'GoCardless' : 'Stripe'}
    </ButtonLink>
  )
}

const payableInvoiceStatus: { [key in PaymentStatus]: boolean } = {
  new: true,
  failed: true,
  cancelled: true,
  refunded: true,
  disputed: false,
  paid: false,
  pending: false,
}

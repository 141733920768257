import { UnreachableCaseError } from '@orus.eu/error'
import { Button, useAsyncCallback, useEnqueueToast } from '@orus.eu/pharaoh'
import { isSuccess } from '@orus.eu/result'
import { sleep } from '@orus.eu/sleep'
import { useState, type ReactText } from 'react'
import { trpc } from '../../../client'

type PayInvoiceButtonProps = {
  invoiceId: string
  refetch: () => Promise<unknown>
  children?: ReactText
  confirmText: string
}

/**
 * This button attempts the payment of an invoice when clicked. After it displays the result and cause the
 * refetch of invoices list, to make sure all information on the page is up-to-date.
 * @param invoiceId
 * @param children
 * @param confirmText
 * @constructor
 */
export function PayInvoiceButton({ invoiceId, children, confirmText, refetch }: PayInvoiceButtonProps): JSX.Element {
  const [state, setState] = useState<'idle' | 'working'>('idle')
  const { enqueueToast } = useEnqueueToast()

  const attemptPayment = useAsyncCallback(async () => {
    setState('working')
    const attemptResult = await trpc.invoices.attemptInvoicePayment.mutate(invoiceId)

    if (isSuccess(attemptResult)) {
      enqueueToast(confirmText, { variant: 'success' })
    } else {
      const { type } = attemptResult.problem
      switch (type) {
        case 'payment-already-in-progress':
          enqueueToast('Un paiement est déjà en cours pour cette facture', { variant: 'warning' })
          break
        case 'payment-not-allowed':
          enqueueToast(`Le paiement de cette facture n'est pas possible : ${attemptResult.problem.reason}`, {
            variant: 'danger',
          })
          break
        case 'no-payment-method':
          enqueueToast('Aucun moyen de paiement enregistré', { variant: 'danger' })
          break
        case 'payment-failed':
          enqueueToast('Le paiement a échoué', { variant: 'danger' })
          break
        case 'unsupported-negative-amount':
          enqueueToast('On ne sait pas payer automatiquement les factures avec un montant négatif', {
            variant: 'danger',
          })
          break
        case 'zero-amount':
          enqueueToast('Il n’est pas possible de payer une facture avec un montant nul', {
            variant: 'danger',
          })
          break
        case 'obsolete-payment-method':
          enqueueToast('Le moyen de paiement est obsolète (par exemple, mandat annulé)', { variant: 'danger' })
          break
        case 'payment-disabled-ongoing-incident':
          enqueueToast('Ce paiement est désactivé par l’équipe tech en raison d’un incident de paiement', {
            variant: 'warning',
          })
          break
        case 'provider-error':
          enqueueToast('Une erreur est survenue lors de la communication avec le prestataire de paiement', {
            variant: 'danger',
          })
          break
        default:
          throw new UnreachableCaseError(type)
      }
    }

    await sleep(2_000)

    await refetch()
  }, [confirmText, invoiceId, enqueueToast, refetch])

  return (
    <Button
      size="small"
      variant="primary"
      disabled={state !== 'idle'}
      onClick={attemptPayment}
      isLoading={state === 'working'}
    >
      {children}
    </Button>
  )
}

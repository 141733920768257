import { css } from '@emotion/react'
import type { InvoicingItem } from '@orus.eu/backend/src/invoicing/views/invoice-payment-status-view'
import { DownloadButtonWithHref, spacing, useLanguage } from '@orus.eu/pharaoh'
import { memo } from 'react'
import { dynamicFileNames } from '../../../lib/download-filenames'
import {
  getAdminInvoiceDownloadLink,
  getAdminMedDownloadLink,
  getAdminReceiptDownloadLink,
} from '../../../lib/download-links'
import { statusAllowsMed } from './types'

type InvoiceDocumentsDownloadButtonsProps = {
  invoicingItem: InvoicingItem
}
export const InvoiceDocumentsDownloadButtons = memo<InvoiceDocumentsDownloadButtonsProps>(
  function InvoiceDocumentDownloadButtons({ invoicingItem }) {
    const language = useLanguage()
    const invoiceId = invoicingItem.invoice.invoiceId

    return (
      <div
        css={css`
          display: flex;
          gap: ${spacing[30]};
        `}
      >
        {invoicingItem.type === 'emitted' && statusAllowsMed[invoicingItem.paymentStatus] ? (
          <DownloadButtonWithHref
            variant="secondary"
            size="small"
            href={getAdminMedDownloadLink(invoiceId)}
            fileName="MED.pdf"
          />
        ) : (
          <></>
        )}
        {invoicingItem.type === 'emitted' && invoicingItem.paymentStatus === 'paid' ? (
          <DownloadButtonWithHref
            variant="secondary"
            size="small"
            href={getAdminReceiptDownloadLink(invoiceId)}
            fileName={dynamicFileNames.receipts(invoicingItem.invoiceNumber, language)}
          />
        ) : (
          <></>
        )}
        {invoicingItem.type === 'emitted' && invoicingItem.paymentStatus !== 'paid' ? (
          <DownloadButtonWithHref
            variant="secondary"
            size="small"
            href={getAdminInvoiceDownloadLink(invoiceId)}
            fileName={dynamicFileNames.invoice(invoicingItem.invoiceNumber, language)}
          />
        ) : (
          <></>
        )}
      </div>
    )
  },
)

import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { Table, TableBody, TableContainer, TableHead, TableRow } from '@mui/material'
import type { BackofficeContractDescription } from '@orus.eu/backend/src/views/backoffice-contract-view'
import type { InvoiceDataV2 } from '@orus.eu/invoice'
import { Button, ButtonLink, Dialog, spacing, Text, useDialogVisibility } from '@orus.eu/pharaoh'
import { memo } from 'react'
import { trpcReact } from '../../../../../../client'
import { formatDateTime } from '../../../../../../lib/format'
import { getInvoicePeriodLabelFromTimestamps } from '../../../../../../lib/invoice-util'
import { BackofficeSubsectionTitle } from '../../../../../atoms/backoffice-subsection-title'
import { TableCellAmount } from '../../../../../molecules/table-elements/table-cell-amount'
import { TableCellHeader } from '../../../../../molecules/table-elements/table-cell-header'
import { TableCellText } from '../../../../../molecules/table-elements/table-cell-text'
import { BackofficeSubscriptionInvoicingConfigurationCard } from '../../../../../organisms/backoffice-subscription-invoing-configuration'
import { ContractInvoicesList } from './components/ContractInvoicesList'

export const ContractInvoicesBlock = memo<{
  contract: BackofficeContractDescription
  canCreateNewInvoice: boolean
  canSeeTheoreticalInvoices: boolean
}>(function InvoicesBlock({ contract, canCreateNewInvoice, canSeeTheoreticalInvoices }) {
  return (
    <div>
      <TitleSectionRow>
        <BackofficeSubsectionTitle>Factures</BackofficeSubsectionTitle>
        <div
          css={css`
            display: flex;
            gap: ${spacing[40]};
            margin-top: ${spacing[80]};
          `}
        >
          {canSeeTheoreticalInvoices ? (
            <InvoicingScheduleButton canSeeTheoreticalInvoices contract={contract} />
          ) : (
            <></>
          )}
          {canCreateNewInvoice ? (
            <ButtonLink
              variant="secondary"
              size="small"
              icon="plus-solid"
              to="/bak/contracts/$subscriptionId/new-invoice"
              params={{ subscriptionId: contract.subscriptionId }}
            >
              Nouvelle facture
            </ButtonLink>
          ) : null}
        </div>
      </TitleSectionRow>
      {canCreateNewInvoice ? (
        <BackofficeSubscriptionInvoicingConfigurationCard subscriptionId={contract.subscriptionId} />
      ) : null}
      <Text variant="caption">
        Les nouvelles factures sont générées avec l’algorithme v{contract.invoicingAlgorithmVersion}.
      </Text>
      <ContractInvoicesList subscriptionId={contract.subscriptionId} />
    </div>
  )
})

const TitleSectionRow = styled.div`
  display: flex;
  justify-content: space-between;
`

function InvoiceListRowV2({ theoreticalInvoice }: { theoreticalInvoice: InvoiceDataV2 }): JSX.Element {
  const { dueTimestamp, periodStartTimestamp, periodEndTimestamp, totalPremium } = theoreticalInvoice

  return (
    <TableRow key={periodStartTimestamp}>
      <TableCellText text={formatDateTime(dueTimestamp)} />
      <TableCellText text={getInvoicePeriodLabelFromTimestamps({ periodEndTimestamp, periodStartTimestamp })} />
      <TableCellAmount amount={totalPremium} />
    </TableRow>
  )
}

export const InvoicingScheduleButton = memo<{
  contract: BackofficeContractDescription
  canSeeTheoreticalInvoices: boolean
}>(function InvoicingScheduleButton({ contract }) {
  const {
    hide: hideInvoiceScheduleDialog,
    show: showInvoiceScheduleDialog,
    visible: isInvoiceScheduleVisible,
  } = useDialogVisibility('invoice-schedule')

  return (
    <>
      <Button variant="secondary" size="small" href={`/bak/contracts/${contract.subscriptionId}/invoicing`}>
        Échéancier (debug)
      </Button>
      <Button variant="secondary" size="small" onClick={showInvoiceScheduleDialog}>
        Dates de facturation théoriques (debug)
      </Button>
      {isInvoiceScheduleVisible ? (
        <InvoicingScheduleDialog contract={contract} onClose={hideInvoiceScheduleDialog} />
      ) : (
        <></>
      )}
    </>
  )
})

export const InvoicingScheduleDialog = memo<{
  contract: BackofficeContractDescription
  onClose: () => void
}>(function InvoicingScheduleDialog({ contract, onClose }) {
  const [invoices] = trpcReact.invoices.listTheoreticalInvoices.useSuspenseQuery(contract.subscriptionId)

  return (
    <Dialog onClose={onClose} title="Echéancier" size="large">
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCellHeader>Due le</TableCellHeader>
              <TableCellHeader>Période</TableCellHeader>
              <TableCellHeader>Montant</TableCellHeader>
            </TableRow>
          </TableHead>
          <TableBody>
            {invoices.map((invoice, index) => (
              <InvoiceListRowV2 key={index} theoreticalInvoice={invoice} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Dialog>
  )
})

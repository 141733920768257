import { css } from '@emotion/react'
import type { ManuallySetPaymentStatus } from '@orus.eu/backend/src/events/payment-update'
import {
  Button,
  Dialog,
  enqueueTemporaryNotificationAlert,
  FlexColumn,
  spacing,
  Spinner,
  Text,
  useAsyncCallback,
  useDialogVisibility,
} from '@orus.eu/pharaoh'
import type { CompoundIconName } from '@orus.eu/pharaoh/src/components/atoms/icon/names'
import { isFailure, isSuccess } from '@orus.eu/result'
import { memo, useEffect } from 'react'
import { trpcReact } from '../../../client'

export const ManualPaymentButton = memo(function ManualPaymentButton({
  invoiceId,
  targetStatus,
  refetch,
}: {
  invoiceId: string
  targetStatus: ManuallySetPaymentStatus
  refetch: () => Promise<unknown>
}) {
  const {
    show: showConfirmDialog,
    hide: hideConfirmDialog,
    visible: isConfirmDialogVisible,
  } = useDialogVisibility(`confirm-mark-as-paid-${invoiceId}`)
  return (
    <>
      <Button
        size="small"
        variant="secondary"
        icon={targetStatusIcon[targetStatus]}
        onClick={showConfirmDialog}
        title={actionLabel[targetStatus]}
        aria-label={actionLabel[targetStatus]}
      />
      {isConfirmDialogVisible && (
        <ConfirmDialog
          invoiceId={invoiceId}
          targetStatus={targetStatus}
          onClose={hideConfirmDialog}
          refetch={refetch}
        />
      )}
    </>
  )
})

const ConfirmDialog = memo(function ConfirmDialog({
  onClose,
  invoiceId,
  targetStatus,
  refetch,
}: {
  onClose: () => void
  invoiceId: string
  targetStatus: ManuallySetPaymentStatus
  refetch: () => Promise<unknown>
}) {
  const {
    isPending,
    mutate: setManualPaymentStatus,
    data: paymentResult,
  } = trpcReact.invoices.setManualPaymentStatus.useMutation()
  const primaryActionAvailable = !isPending && !paymentResult

  const closeAndRefetch = useAsyncCallback(async () => {
    onClose()
    await refetch()
  }, [onClose, refetch])

  const isWaitingForConfirmation = !isPending && !paymentResult
  const isDone = !!paymentResult && isSuccess(paymentResult)
  const isFailed = !!paymentResult && isFailure(paymentResult)

  useEffect(() => {
    if (isDone) {
      enqueueTemporaryNotificationAlert(actionDoneDescription[targetStatus], {
        variant: 'success',
        autoHideDuration: 3000,
      })
      closeAndRefetch()
    }
  }, [isDone, targetStatus, closeAndRefetch])

  const handleClose = isPending ? undefined : onClose

  return (
    <Dialog
      title={isDone ? actionDoneLabel[targetStatus] : actionLabel[targetStatus]}
      isBackoffice={true}
      size="medium"
      primaryActionLabel={primaryActionAvailable ? 'Confirmer' : undefined}
      secondaryActionLabel={isPending ? undefined : primaryActionAvailable ? 'Annuler' : 'OK'}
      onSecondaryAction={handleClose}
      onClose={handleClose}
      onPrimaryAction={
        primaryActionAvailable
          ? () => setManualPaymentStatus({ invoiceId, manuallySetStatus: targetStatus })
          : undefined
      }
    >
      <FlexColumn
        css={css`
          gap: ${spacing['20']};
          margin-left: ${isPending ? 'auto' : '0'};
        `}
      >
        {isFailed ? (
          <>
            <Text>{actionFailedDescription[targetStatus]}</Text>
            <Text>
              {paymentResult.problem.type === 'payment-not-allowed'
                ? paymentResult.problem.reason
                : paymentResult.problem.type}
            </Text>
          </>
        ) : null}

        {isWaitingForConfirmation ? (
          <>
            <Text>{actionDescription[targetStatus]}</Text>
            <Text>
              Les informations du prestataire de paiement seront ignorées. Cela doit rester une opération exceptionnelle
              suite à une gestion manuelle du paiement.
            </Text>
          </>
        ) : null}

        {isPending ? <Spinner /> : null}
      </FlexColumn>
    </Dialog>
  )
})

const targetStatusIcon: Record<ManuallySetPaymentStatus, CompoundIconName> = {
  paid: 'check-regular',
  failed: 'xmark-regular',
}

const actionLabel: Record<ManuallySetPaymentStatus, string> = {
  paid: 'Marquer la facture comme payée',
  failed: 'Marquer la facture comme non payée',
}

const actionDoneLabel: Record<ManuallySetPaymentStatus, string> = {
  paid: 'Facture marquée comme payée',
  failed: 'Facture marquée comme non payée',
}

const actionDoneDescription: Record<ManuallySetPaymentStatus, string> = {
  paid: 'La facture a été marquée comme payée !',
  failed: 'La facture a été marquée comme non payée !',
}

const actionFailedDescription: Record<ManuallySetPaymentStatus, string> = {
  paid: 'Impossible de marquer la facture comme payée',
  failed: 'Impossible de marquer la facture comme non payée',
}

const actionDescription: Record<ManuallySetPaymentStatus, string> = {
  paid: 'Cette action marque manuellement la facture comme payée.',
  failed: 'Cette action marque manuellement la facture comme non payée.',
}
